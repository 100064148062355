import dayjs, { Dayjs } from "dayjs";
import { SelectOption } from "../../../Common/models/form";

const currentDate = dayjs();

export const defaultValues = {
  title: "",
  synopsis: "",
  article: "",
  alertTitle: "",
  alertBody: "",
  tools: [""],
  publicationDate: currentDate,
  publicationMode: "draft",
};

export type FaqArticleFormType = typeof defaultValues & {
  tools: string[];
  endDate?: Dayjs;
  isDraft: boolean;
};

export const publicationModeOptions = () =>
  [
    {
      label: "Draft",
      value: "draft",
    },
    {
      label: "Final",
      value: "final",
    },
  ] as SelectOption[];
