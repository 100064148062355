import { useState } from "react";
import { useForm } from "react-hook-form";
import { createFaqArticle } from "../api/createFaqHttpClient";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import {
  FaqArticleFormType,
  defaultValues,
} from "../../Common/models/FaqArticle";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

const useCreateFaq = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FaqArticleFormType>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const submit = async () => {
    if (!(await form.trigger("publicationDate"))) {
      enqueueSnackbar("Publication date cannot be empty", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      return;
    }

    const values = form.getValues();
    dayjs.extend(isSameOrBefore);

    if (
      values.endDate &&
      values.endDate.isSameOrBefore(values.publicationDate, "date")
    ) {
      enqueueSnackbar(
        "End date cannot be earlier or equal to the publication date",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      return;
    }

    values.isDraft = values.publicationMode === "draft";
    values.endDate = values.endDate ? values.endDate.add(1, "d") : undefined;

    if (await form.trigger()) {
      setIsLoading(true);

      try {
        await createFaqArticle(values);
        enqueueSnackbar("Created new FAQ successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        navigate(routes.faq);
      } catch {
        enqueueSnackbar("Something went wrong while creating new FAQ", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Please correct the validation errors", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  return { form, submit, isLoading };
};

export default useCreateFaq;
