import { SxProps, Theme } from "@mui/material";
import { useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface HeiDatePickerProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  minDate?: Dayjs;
  format?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const HeiDatePicker = <TForm extends FieldValues>({
  control,
  name,
  label,
  minDate,
  format,
  sx,
  rules,
  disabled,
}: HeiDatePickerProps<TForm>): JSX.Element => {
  const required = rules?.required;
  const [error, setError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case "minDate":
        return `Date cannot be earlier than ${minDate?.format(
          format || "DD-MM-YYYY"
        )}`;
      case "invalidDate":
        return "Your date is not valid";
      default:
        return "";
    }
  }, [error]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <DatePicker
              {...field}
              onError={(newError) => setError(newError)}
              sx={sx || { width: "45%" }}
              minDate={minDate}
              slotProps={{
                textField: {
                  required: !!required,
                  error: !!error,
                  helperText: errorMessage,
                  InputLabelProps: { shrink: true },
                },
              }}
              label={label}
              format={format || "DD-MM-YYYY"}
              disabled={disabled}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default HeiDatePicker;
